import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { inject } from '@angular/core';

export const authenticationGuard: CanActivateFn = (route, state) => {
  const apiService = inject(ApiService);
  const router = inject(Router);
  // console.log('-------' + apiService.isAuthenticated);

  if (apiService.getIsAuthenticated()) {
    // alert('*********');
    return true;
  }
  return router.navigateByUrl('/login');
};

export const authenticationCanMatch: CanMatchFn = (route, segments) => {
  const apiService = inject(ApiService);
  const router = inject(Router);
  // console.log('++++++++' + apiService.isAuthenticated);

  if (apiService.getIsAuthenticated()) {
    return true;
  }
  return router.navigateByUrl('/login');
};
