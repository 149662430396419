import { Component } from '@angular/core';
import { config } from '@fortawesome/fontawesome-svg-core';
import { RedirectionService } from './service/redirection.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
// import { AuthenticationService } from './service/authentication.service';
import { StrapiService } from './service/strapi.service';
config.autoAddCss = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  isTemplateVisible: boolean = true;
  isFloatingBtnVisible: boolean = true;
  isHome: boolean = false;
  isShowed: boolean = false;
  showPop: boolean = true;
  // Don't remove RedirectionService injection , it is used to do redirection
  constructor(
    private redirectionService: RedirectionService,
    private router: Router,
    private strapi: StrapiService
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.logRedirection(event.urlAfterRedirects, event.url);
      });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isTemplateVisible = !this.router.url.includes('error');
        this.isShowed =
          this.router.url.includes('/blog') ||
          this.router.url.includes('/articles') ||
          this.router.url.includes('/categories') ||
          this.router.url.includes('/all-articles');
        this.isFloatingBtnVisible = !this.router.url.includes('blog');
        this.showPop = !this.router.url.includes('/admin');
      });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isHome = this.router.url === '/';
      });

    // this.strapi.getArticles().subscribe({
    //   next: (data) => {
    //     console.log(data);
    //   },
    //   error: (err) => {
    //     console.log(err);
    //   },
    // });
  }

  private logRedirection(redirectedUrl: string, originalUrl: string) {
    //console.log('Redirection detected:', redirectedUrl, originalUrl);
  }
}
