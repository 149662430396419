import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SouscriptionComponent } from './souscription.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { SouscriptionRoutingModule } from './souscription-routing.module';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SouscriptionComponent],
  imports: [
    CommonModule,
    SouscriptionRoutingModule,
    FormsModule /*, DashboardModule*/,
    ReactiveFormsModule,
  ],
  exports: [SouscriptionComponent],
})
export class SouscriptionModule {}
