import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Route, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-popup-blackfriday',
  templateUrl: './popup-blackfriday.component.html',
  styleUrls: ['./popup-blackfriday.component.css'],
  animations: [
    trigger('popupAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.8)' }),
        animate('500ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        animate(
          '500ms ease-in',
          style({ opacity: 0, transform: 'scale(0.8)' })
        ),
      ]),
    ]),
  ],
})
export class PopupBlackfridayComponent implements OnInit {
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  isCountdownFinished: boolean = false;
  @Input() isVisible: boolean = true;
  constructor(private route: Router) {}
  ngOnInit(): void {
    // console.log('++++++++++++++++++++++++');
    // Date cible en heure du Royaume-Uni
    /* const targetDate = new Date('2024-11-30T23:59:59Z'); // UTC TimeZone (Europe/London)
    conso
  /*  this.updateCountdown(targetDate);
    setInterval(() => this.updateCountdown(targetDate), 1000);*/
    /* setTimeout(() => {
      this.isVisible = true;
    }, 3000); // 5000 millisecondes = 5 secondes*/
    this.route.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // console.log('123');
        if (this.route.url.includes('/subscribe')) this.closePopup();
      });
  }

  /*  updateCountdown(targetDate: Date) {
    const now = this.getUKTime(); // Obtenir l'heure actuelle au Royaume-Uni
    const diff = targetDate.getTime() - now.getTime();

    if (diff <= 0) {
      this.isCountdownFinished = true;
      return;
    }

    this.days = Math.floor(diff / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((diff % (1000 * 60)) / 1000);
  }

  getUKTime(): Date {
    // Convertir l'heure actuelle en fuseau horaire du Royaume-Uni
    const formatter = new Intl.DateTimeFormat('en-GB', {
      timeZone: 'Europe/London',
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    const parts = formatter.formatToParts(new Date());
    const year = Number(parts.find((part) => part.type === 'year')?.value);
    const month =
      Number(parts.find((part) => part.type === 'month')?.value) - 1; // Mois indexé à 0
    const day = Number(parts.find((part) => part.type === 'day')?.value);
    const hour = Number(parts.find((part) => part.type === 'hour')?.value);
    const minute = Number(parts.find((part) => part.type === 'minute')?.value);
    const second = Number(parts.find((part) => part.type === 'second')?.value);

    return new Date(year, month, day, hour, minute, second);
  }
  

  get formattedDays(): string {
    return this.days.toString().padStart(2, '0');
  }

  get formattedHours(): string {
    return this.hours.toString().padStart(2, '0');
  }

  get formattedMinutes(): string {
    return this.minutes.toString().padStart(2, '0');
  }

  get formattedSeconds(): string {
    return this.seconds.toString().padStart(2, '0');
  }
*/
  goToBlackFriday() {
    this.closePopup();
    // this.route.navigateByUrl('/blackfriday');
    this.route.navigateByUrl('/subscribe');
  }
  closePopup(): void {
    this.isVisible = false;
  }
}
