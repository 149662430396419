import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-souscription',
  templateUrl: './souscription.component.html',
  styleUrls: ['./souscription.component.css'],
})
export class SouscriptionComponent {
  subForm: FormGroup;
  result: any = { title: '', content: '' };
  @ViewChild('btnShowModal') btnShowModal;
  @ViewChild('btnCloseModal') btnCloseModal;
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private root: Router
  ) {
    this.subForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });
  }
  onSubmit() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.http
      .post(
        `https://email.luxuryholidaysdirect.com/contact/add?version=2&publicaccountid=7ed18b4e-56d5-423c-878e-f8a4a07cbc53&email=${this.subForm.value.email}`,
        {
          email: this.subForm.value.email,
          field_firstname: this.subForm.value.firstName,
          field_lastname: this.subForm.value.lastName,
          publiclistid: 'cd405fda-994e-40f5-9aa8-c946073594c9',
          submit: 'Subscribe Now',
          publicaccountid: '7ed18b4e-56d5-423c-878e-f8a4a07cbc53',
          returnUrl: '',
          activationReturnUrl: '',
          alreadyactiveurl: '',
          activationTemplate: '',
          source: 'WebForm',
          verifyemail: 'false',
          captcha: 'true',
          sendActivation: 'true',
          notifyEmail: '',
        },
        httpOptions
      )
      .subscribe({
        next: (res) => {
          this.result.title = 'Success !';
          this.result.message =
            "Thank You for Subscribing! We're excited to keep you updated. ";
          this.btnShowModal.nativeElement.click();
        },
        error: (err) => {
          //     console.log(err);
          this.result.title = 'Error !';
          this.result.message = 'Subscription failed. Try again ! ';
          this.btnShowModal.nativeElement.click();
        },
      });
  }
  onClickedOk() {
    this.btnCloseModal.nativeElement.click();
    if (this.result.title.includes('Success')) {
      this.root.navigateByUrl('/');
    }
  }
  get email() {
    return this.subForm.get('email');
  }
  get firstName() {
    return this.subForm.get('firstName');
  }
  get lastName() {
    return this.subForm.get('lastName');
  }
}
