import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { filter, retry } from 'rxjs/operators';
import { Hotel } from '../models/hotel';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl = environment.API_BASE_URL;
  this: any;
  getMoreData: any;
  private isAuthenticated: boolean = false;
  private username!: string;
  private roles!: string;
  private accessToken!: string;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    // console.log('------------ constructor' + this.getToken());
    if (this.getToken()) {
      this.accessToken = this.getToken();
      this.isAuthenticated = true;
    }
  }

  getHotels(): Observable<Hotel[]> {
    const url = `${this.apiUrl}` + 'hotels';
    return this.http.get<Hotel[]>(url);
  }

  gethotel(id: number): Observable<Hotel> {
    const url = `${this.apiUrl}` + 'hotels' + '/' + `${id}`;
    return this.http.get<Hotel>(url);
  }

  getContinents(): Observable<any> {
    const url = `${this.apiUrl}` + 'destinations/destination-continent';
    return this.http.get<any>(url);
  }

  getDestinations(
    destinationId: string | undefined,
    destinationName: string
  ): Observable<any> {
    const url =
      `${this.apiUrl}` +
      'destinations/' +
      destinationName +
      '/' +
      destinationId;
    return this.http.get<any>(url);
  }
  gettopOffers(): Observable<any> {
    const url = `${this.apiUrl}` + 'offers/top-offers';
    return this.http
      .get<any>(url, {
        observe: 'events',
        reportProgress: true,
        responseType: 'text' as 'json',
      })
      .pipe(
        filter((e: any) => e.type === HttpEventType.Response && e.body),
        map((e) => e.body.trim().split(/\r\n|\r|\n/))
      );
  }
  getOffers(): Observable<any> {
    const url = `${this.apiUrl}` + 'offers/get-offers';
    return this.http.post<any>(url, {
      categoryDTOList: [],
      durationDTOList: [],
      minMaxPrices: [],
    });
  }
  getImage(apiUrl: string): Observable<Blob> {
    return this.http.get(apiUrl, { responseType: 'blob' });
  }

  gethoneymoonoffer(): Observable<any> {
    const url = `${this.apiUrl}` + 'offers/honeymoon-offers';
    return this.http
      .get<any>(url, {
        observe: 'events',
        reportProgress: true,
        responseType: 'text' as 'json',
      })
      .pipe(
        filter((e: any) => e.type === HttpEventType.Response && e.body),
        map((e) => e.body.trim().split(/\r\n|\r|\n/))
      );
  }
  gettopHoneymoonOffer(): Observable<any> {
    const url = `${this.apiUrl}` + 'offers/top-honeymoon-offers';
    return this.http
      .get<any>(url, {
        observe: 'events',
        reportProgress: true,
        responseType: 'text' as 'json',
      })
      .pipe(
        filter((e: any) => e.type === HttpEventType.Response && e.body),
        map((e) => e.body.trim().split(/\r\n|\r|\n/))
      );
  }

  gettopExclusiveHoneymoonOffer(): Observable<any> {
    const url = `${this.apiUrl}` + 'offers/top-honeymoon-exclusive-offers';
    return this.http
      .get<any>(url, {
        observe: 'events',
        reportProgress: true,
        responseType: 'text' as 'json',
      })
      .pipe(
        filter((e: any) => e.type === HttpEventType.Response && e.body),
        map((e) => e.body.trim().split(/\r\n|\r|\n/))
      );
  }

  getDestination(): Observable<any> {
    const url = `${this.apiUrl}` + 'destinations';
    return this.http.get<any>(url);
  }
  getHotelNotes(hotelId: number): Observable<Hotel[]> {
    const url = `${this.apiUrl}hotel-supplement/notes?hotelId=${hotelId}`;
    return this.http.get<Hotel[]>(url);
  }
  getTravel(hotelId: number): Observable<Hotel[]> {
    const url = `${this.apiUrl}hotel-supplement/travel?hotelId=${hotelId}`;
    return this.http.get<Hotel[]>(url);
  }
  getHoneymoon(): Observable<any> {
    const url =
      `${this.apiUrl}` + 'hotels/getHotelByCategory?HotelCategory=HONEYMOON';
    return this.http.get<any>(url);
  }
  getBeach(): Observable<any> {
    const url =
      `${this.apiUrl}` +
      'hotels/getHotelByCategory?HotelCategory=BEACH_HOLIDAY';
    return this.http.get<any>(url);
  }
  getFamily(): Observable<any> {
    const url =
      `${this.apiUrl}` +
      'hotels/getHotelByCategory?HotelCategory=FAMILY_FRIENDS';
    return this.http.get<any>(url);
  }
  getWild(): Observable<any> {
    const url =
      `${this.apiUrl}` + 'hotels/getHotelByCategory?HotelCategory=WILD_NATURE';
    return this.http.get<any>(url);
  }
  getAllinclusive(): Observable<any> {
    const url =
      `${this.apiUrl}` +
      'hotels/getHotelByCategory?HotelCategory=ALL_INCLUSIVE';
    return this.http.get<any>(url);
  }
  getTour(): Observable<any> {
    const url = `${this.apiUrl}` + 'tours';
    return this.http.get<any>(url);
  }
  getTourpackage(tourHotelId: number): Observable<any> {
    const url = `${this.apiUrl}tours/${tourHotelId}?tourHotelId=${tourHotelId}`;
    return this.http.get<any>(url);
  }
  login(username: string, password: string): Observable<any> {
    const httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
    let params = new HttpParams()
      .set('username', username)
      .set('password', password);
    return this.http.post<any>(
      `${this.apiUrl.replace('v1/', '')}auth/login`,
      params,
      httpOption
    );
  }
  loadUserProfil(data: any) {
    this.accessToken = data['access-token'];
    let encodedJwt: any = jwtDecode(this.accessToken);
    console.log(encodedJwt);
    this.username = encodedJwt.sub;
    this.roles = encodedJwt.scope;
    this.isAuthenticated = true;
    this.setToken(this.accessToken);
  }

  deleteHotel(hotelId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.apiUrl}hotels/hotel?hotelId=${hotelId}`
    );
  }
  setToken(token: string) {
    this.cookieService.set('access-token', token, {
      expires: 1,
      secure: true,
      sameSite: 'Strict',
    });
  }
  getToken(): string {
    return this.cookieService.get('access-token');
  }
  removeToken(): void {
    this.cookieService.delete('access-token');
  }
  getIsAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  saveHotel(hotel: any, hotelId: string): Observable<any> {
    return this.http.post<any>(
      hotelId
        ? `${this.apiUrl}hotels?hotelId=${hotelId}`
        : `${this.apiUrl}hotels`,
      hotel
    );
  }
  /*setHotelRooms(hotelId: string, rooms: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}hotels/rooms?hotelId=${hotelId}`,
      rooms
    );
  }*/
  setHotelCategory(hotelId: string, categories: string[]) {
    return this.http.post<any>(
      `${this.apiUrl}hotels/set-hotel-categories?hotelId=${hotelId}`,
      categories
    );
  }
  setHotelActivity(hotelId: string, activity: any) {
    return this.http.post<any>(
      `${this.apiUrl}hotels/activities?hotelId=${hotelId}`,
      activity
    );
  }
  setHotelSupplement(hotelId: string, supplement: any) {
    return this.http.post<any>(
      `${this.apiUrl}hotels/hotel-supplement/travel?hotelId=${hotelId}`,
      supplement
    );
  }
  setHotelFacility(
    hotelId: string,
    hotelFacilities: any[]
  ): Observable<Array<any>> {
    return this.http.post<Array<any>>(
      `${this.apiUrl}hotels/set-hotel-facilities?hotelId=${hotelId}`,
      hotelFacilities
    );
  }
  setHotelOffer(offers: any, offerId: string): Observable<any> {
    return this.http.post<any>(
      offerId
        ? `${this.apiUrl}offers?offerId=${offerId}`
        : `${this.apiUrl}offers`,
      offers
    );
  }
  setHotelRooms(
    hotelId: string,
    hotelRooms: Array<any>
  ): Observable<Array<any>> {
    return this.http.post<Array<any>>(
      `${this.apiUrl}hotels/set-rooms?hotelId=${hotelId}`,
      hotelRooms
    );
  }
  getQuotes(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}quote`);
  }
  getQuote(quoteId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}quote/${quoteId}`);
  }
  deleteAcitvityAttribute(attributeId: string): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}hotels/activities/attribute?attributeId=${attributeId}`
    );
  }
  deleteAcitvity(hotelActivityId: string): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}hotels/activities?hotelActivityId=${hotelActivityId}`
    );
  }

  getFullInfoHotel(hotelId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}hotels/${hotelId}`);
  }
  deleteHotelRoom(hotelRoomId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.apiUrl}hotels/rooms?hotelRoomsId=${hotelRoomId}`
    );
  }
  gethotelOffer(hotelId: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}offers/get-offer-by-hotelId?hotelId=${hotelId}`
    );
  }
  deleteFacility(hotelFacilityId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.apiUrl}hotels/facilities?hotelFacilityId=${hotelFacilityId}`
    );
  }
}
