<div class="popup-container" *ngIf="isVisible" @popupAnimation>
  <button class="fermer" (click)="closePopup()">&#x2715;</button>
  <img
    src="assets/images/lhd/LUXURY white.png"
    alt="Logo luxury holidays direct"
    class="black-img"
  />
  <h2 class="black-heading">Stay Updated</h2>
  <p class="black-text">
    <!-- Exclusive luxury escapes with savings up to 55%. You dream vacation awaits. -->
    Subscribe to our newsletter and be the first to receive exclusive offers,
    travel tips, and the latest updates. No spam, just great content!
  </p>

  <button class="btn-show--offers" (click)="goToBlackFriday()">
    <!-- Our Offers -->
    Subscribe now
  </button>
  <!-- <div class="timers">
    <span class="chrono"
      ><img src="assets/icons/chronograph-watch.png" alt="chrono" width="20px"
    /></span>
    <div class="days">
      <div class="day">{{ formattedDays }}</div>
      <div class="day-letter">Days</div>
    </div>
    <div class="hours">
      <div class="hour">{{ formattedHours }}</div>
      <div class="hour-letter">hours</div>
    </div>
    <div class="minutes">
      <div class="minute">{{ formattedMinutes }}</div>
      <div class="minute-letter">minutes</div>
    </div>
    <div class="seconds">
      <div class="second">{{ formattedSeconds }}</div>
      <div class="second-letter">seconds</div>
    </div>
  </div> -->
</div>
