import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../service/api.service';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  constructor(private apiService: ApiService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // console.log(
    //   (request.method == 'POST' ||
    //     request.method == 'PUT' ||
    //     request.method == 'DELETE') &&
    //     this.apiService.getIsAuthenticated()
    // );
    if (
      (request.method == 'POST' ||
        request.method == 'PUT' ||
        request.method == 'DELETE') &&
      this.apiService.getIsAuthenticated()
    ) {
      let newRequest = request.clone({
        headers: request.headers.set(
          'Authorization',
          `Bearer ${this.apiService.getToken()}`
        ),
      });
      return next.handle(newRequest);
    } else {
      return next.handle(request);
    }
  }
}
