import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupBlackfridayComponent } from './popup-blackfriday.component';

@NgModule({
  declarations: [PopupBlackfridayComponent],
  imports: [CommonModule],
  exports: [PopupBlackfridayComponent],
})
export class PopupBlackfridayModule {}
