<!-- <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta charset="UTF-8" />
</head>
<body style="padding: 0; margin: 0; background: #d4d1db"> -->
<!-- <app-dashboard
  style="position: fixed; z-index: 10000; width: 100%"
></app-dashboard> -->
<!-- <table
  style="height: 100%; width: 100%; background-color: #d4d1db"
  align="center"
>
  <tbody>
    <tr>
      <td
        valign="top"
        id="dbody"
        data-version="2.31"
        class="mt__table"
        style="
          width: 100%;
          height: 100%;
          margin-top: 50px;
          margin-bottom: 50px;
          padding-top: 90px;
          padding-bottom: 90px;
          background-color: #d4d1db;
        "
      >
        <table
          align="center"
          style="max-width: 1024px"
          width="100%"
          cellpadding="0"
          cellspacing="0"
          border="0"
        >
          <tr>
            <td valign="top">
              <table
                class="layer_1"
                align="center"
                border="0"
                cellpadding="0"
                cellspacing="0"
                style="
                  border-color: #684785;
                  max-width: 1024px;
                  box-sizing: border-box;
                  width: 100%;
                  margin: 0px auto;
                "
              >
                <tbody>
                  <tr>
                    <td
                      class="drow"
                      valign="top"
                      align="center"
                      style="
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-size: 0px;
                        text-align: center;
                      "
                    >
                      <table
                        width="100%"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tr>
                          <td valign="top">
                            <div
                              class="layer_2"
                              style="
                                max-width: 1024px;
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                cellpadding="0"
                                class="edcontent"
                                style="border-collapse: collapse; width: 100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edimg"
                                      style="
                                        padding: 20px;
                                        box-sizing: border-box;
                                        text-align: center;
                                      "
                                    >
                                      <img
                                        src="./assets/images/lhd/LHD-black-png.jpg"
                                        alt="Image"
                                        style="
                                          border-width: 0px;
                                          border-style: none;
                                          max-width: 337px;
                                          width: 100%;
                                        "
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="drow"
                      valign="top"
                      align="center"
                      style="
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-size: 0px;
                        text-align: center;
                      "
                    >
                      <table
                        width="100%"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tr>
                          <td valign="top">
                            <div
                              class="layer_2"
                              style="
                                max-width: 1024px;
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                class="edcontent"
                                style="
                                  border-collapse: collapse;
                                  width: 100%;
                                  background-color: #f0f2f8;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edtext"
                                      style="
                                        padding: 20px;
                                        text-align: left;
                                        color: #32325c;
                                        font-size: 13px;
                                        font-family: 'Open Sans',
                                          'Helvetica Neue', Helvetica, Arial,
                                          sans-serif;
                                        word-break: break-word;
                                        direction: ltr;
                                        box-sizing: border-box;
                                      "
                                    >
                                      <p
                                        class="text-center"
                                        style="text-align: center"
                                      >
                                        <strong
                                          ><span style="color: #2b9297"
                                            ><span style="font-size: 30px"
                                              >Tailor Your Dream
                                              Getaways:&nbsp;</span
                                            ></span
                                          ></strong
                                        >
                                      </p>
                                      <p
                                        class="text-center"
                                        style="text-align: center"
                                      >
                                        <strong
                                          ><span style="color: #2b9297"
                                            ><span style="font-size: 30px"
                                              >Get Personalised Travel
                                              News!</span
                                            ></span
                                          ></strong
                                        >
                                      </p>
                                      <p
                                        class="text-center"
                                        style="text-align: center"
                                      >
                                        <strong
                                          ><span style="color: #2b9297"
                                            ><span style="font-size: 30px"
                                              >Subscribe to&nbsp;</span
                                            ></span
                                          ></strong
                                        ><strong
                                          ><span style="color: #2b9297"
                                            ><span style="font-size: 30px"
                                              >Luxury Holidays Direct n</span
                                            ></span
                                          ></strong
                                        ><strong
                                          ><span style="color: #2b9297"
                                            ><span style="font-size: 30px"
                                              >ewsletter</span
                                            ></span
                                          ></strong
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="drow"
                      valign="top"
                      align="center"
                      style="
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-size: 0px;
                        text-align: center;
                      "
                    >
                      <table
                        width="100%"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tr>
                          <td valign="top">
                            <div
                              class="layer_2"
                              style="
                                font-size: initial;
                                max-width: 512px;
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                cellpadding="0"
                                class="edcontent"
                                style="border-collapse: collapse; width: 100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      align="center"
                                      class="edform"
                                      style="text-align: center; padding: 20px"
                                    >
                                      <span
                                        class="formWithCss"
                                        id="form2023-03-22T16_02_301720017348"
                                      >
                                        <form name="editor-attributes">
                                          <input
                                            type="hidden"
                                            name="formid"
                                            value="form2023-03-22T16_02_301720017348"
                                          />
                                        </form>
                                        <style>
                                          label {
                                            font-weight: 600;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form {
                                            background: #f0f2f8;
                                            padding: 40px;
                                            border-radius: 6px;
                                            box-sizing: border-box;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            p {
                                            color: #7d8da8;
                                            text-align: left;
                                            line-height: 14px;
                                            font-size: 12px;
                                            font-family: Arial, Helvetica,
                                              sans-serif;
                                            margin: 12px 0px;
                                            font-style: normal;
                                            font-weight: normal;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            .EWF__header {
                                            background: transparent;
                                            padding: 0px;
                                            color: #2b9297;
                                            text-align: center;
                                            line-height: 36px;
                                            font-size: 32px;
                                            margin: 0px;
                                            font-family: Arial, Helvetica,
                                              sans-serif;
                                            font-style: normal;
                                            font-weight: bold;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            label {
                                            line-height: 16px;
                                            font-size: 14px;
                                            color: #32325c;
                                            display: block;
                                            font-family: Arial, Helvetica,
                                              sans-serif;
                                            margin-bottom: 5px;
                                            font-style: normal;
                                            font-weight: bold;
                                            text-align: left;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            .EWF__subscribe {
                                            background: #2b9297;
                                            padding: 12px;
                                            line-height: 18px;
                                            font-size: 16px;
                                            color: #ffffff;
                                            border-radius: 6px;
                                            border: 0px;
                                            display: inline-block;
                                            width: auto;
                                            max-width: 100%;
                                            font-family: Arial, Helvetica,
                                              sans-serif;
                                            font-style: normal;
                                            font-weight: normal;
                                            cursor: pointer;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            .EWF__form
                                            .webform-options {
                                            text-align: center;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            input {
                                            background: #ffffff;
                                            padding: 12px;
                                            color: #000000;
                                            border-radius: 0px;
                                            line-height: 18px;
                                            font-size: 16px;
                                            border: 0px;
                                            width: 100%;
                                            max-width: 100%;
                                            display: block;
                                            font-family: Arial, Helvetica,
                                              sans-serif;
                                            font-style: normal;
                                            font-weight: normal;
                                            text-align: left;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            input::placeholder {
                                            color: #000000;
                                            font-size: 14px;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            .inputs
                                            > div {
                                            margin-bottom: 1rem;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            .checkboxes
                                            label,
                                          form.EWF__form .consent label {
                                            position: relative;
                                            cursor: pointer;
                                            user-select: none;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            * {
                                            box-sizing: border-box;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            fieldset {
                                            display: block;
                                            padding: 0px;
                                            margin: 0px;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            .checkboxes
                                            input[type="checkbox"],
                                          form.EWF__form
                                            .consent
                                            input[type="checkbox"] {
                                            border: 1px solid #cfd6db;
                                            border-radius: 4px;
                                            padding: 12px;
                                            width: 0px;
                                            display: inline-block;
                                            margin-right: 20px;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            .checkboxes
                                            input[type="checkbox"]::before,
                                          form.EWF__form
                                            .consent
                                            input[type="checkbox"]::before {
                                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Warstwa_1' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve' width='16' height='16'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;%7D .st1%7Bfill:%23FFFFFF;stroke:%23CFD6DB;%7D%0A%3C/style%3E%3Cg transform='translate(-925 -324)'%3E%3Cg transform='translate(925 324)'%3E%3Cpath class='st0' d='M4,0h8c2.2,0,4,1.8,4,4v8c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4V4C0,1.8,1.8,0,4,0z'/%3E%3Cpath class='st1' d='M4,0.5h8c1.9,0,3.5,1.6,3.5,3.5v8c0,1.9-1.6,3.5-3.5,3.5H4c-1.9,0-3.5-1.6-3.5-3.5V4C0.5,2.1,2.1,0.5,4,0.5z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                            background-color: #ffffff;
                                            width: 16px;
                                            height: 16px;
                                            content: "";
                                            display: block;
                                            cursor: pointer;
                                            border-radius: 4px;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            .checkboxes
                                            input[type="checkbox"]:hover::before,
                                          form.EWF__form
                                            .consent
                                            input[type="checkbox"]:hover::before {
                                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23fff;stroke:%235457ff;stroke-width:2px;%7D.b%7Bstroke:none;%7D.c%7Bfill:none;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-925 -324)'%3E%3Cg class='a' transform='translate(925 324)'%3E%3Crect class='b' width='16' height='16' rx='4'/%3E%3Crect class='c' x='1' y='1' width='14' height='14' rx='3'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                            background-color: #ffffff;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            .checkboxes
                                            input[type="checkbox"]:checked::before,
                                          form.EWF__form
                                            .consent
                                            input[type="checkbox"]:checked::before {
                                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Warstwa_1' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve' width='16' height='16'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;%7D .st1%7Bfill:%23FFFFFF;stroke:%235457FF;%7D .st2%7Bfill:%2332325C;%7D%0A%3C/style%3E%3Cg transform='translate(-925 -324)'%3E%3Cg transform='translate(925 324)'%3E%3Cpath class='st0' d='M4,0h8c2.2,0,4,1.8,4,4v8c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4V4C0,1.8,1.8,0,4,0z'/%3E%3Cpath class='st1' d='M4,0.5h8c1.9,0,3.5,1.6,3.5,3.5v8c0,1.9-1.6,3.5-3.5,3.5H4c-1.9,0-3.5-1.6-3.5-3.5V4C0.5,2.1,2.1,0.5,4,0.5z'/%3E%3C/g%3E%3Cpath class='st2' d='M938,328.9l-0.8-0.8c-0.1-0.1-0.1-0.1-0.2,0c0,0,0,0,0,0l-5.6,5.9l-2.3-2.4c-0.1-0.1-0.1-0.1-0.2,0 c0,0,0,0,0,0l-0.8,0.8c-0.1,0.1-0.1,0.2,0,0.2l3.2,3.3c0.1,0.1,0.1,0.1,0.2,0c0,0,0,0,0,0l6.5-6.9C938,329,938,328.9,938,328.9z'/%3E%3C/g%3E%3C/svg%3E");
                                            background-color: #ffffff;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            .checkboxes
                                            input[type="checkbox"]:hover:checked::before,
                                          form.EWF__form
                                            .consent
                                            input[type="checkbox"]:hover:checked::before {
                                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23fff;stroke:%235457ff;stroke-width:2px;%7D.b%7Bfill:%2332325c;%7D.c%7Bstroke:none;%7D.d%7Bfill:none;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-925 -324)'%3E%3Cg class='a' transform='translate(925 324)'%3E%3Crect class='c' width='16' height='16' rx='4'/%3E%3Crect class='d' x='1' y='1' width='14' height='14' rx='3'/%3E%3C/g%3E%3Cpath class='b' d='M10.032.964,9.224.118a.141.141,0,0,0-.2,0L3.384,6.02,1.124,3.653a.141.141,0,0,0-.2,0L.116,4.5a.157.157,0,0,0,0,.212L3.284,8.032a.141.141,0,0,0,.2,0l6.545-6.855A.153.153,0,0,0,10.032.964Z' transform='translate(927.925 327.925)'/%3E%3C/g%3E%3C/svg%3E");
                                            background-color: #ffffff;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            .EWF__error {
                                            color: red;
                                            padding-top: 10px;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            a {
                                            color: #5457ff;
                                            line-height: 14px;
                                            font-size: 12px;
                                            font-weight: normal;
                                          }
                                          #form2023-03-22T16_02_301720017348
                                            form.EWF__form
                                            .EWF__subscribe:disabled {
                                            cursor: default;
                                          }
                                          @supports (-ms-ime-align: auto) {
                                            form.EWF__form
                                              .checkboxes
                                              input[type="checkbox"],
                                            form.EWF__form
                                              .consent
                                              input[type="checkbox"] {
                                              width: 16px !important;
                                              margin-right: 5px;
                                            }
                                          }
                                        </style>
                                        <form
                                          action="http://localhost:3000/receive-post"
                                          data-id="VTpwcCfJke"
                                          method="post"
                                          id="ewf_subscriptionForm_VTpwcCfJke"
                                          class="EWF__form Inline"
                                        >
                                          <div class="headers" style="">
                                            <h4
                                              id="ewf_formheader"
                                              class="EWF__header"
                                            >
                                              Subscribe to our newsletter
                                            </h4>
                                            <p id="ewf_formdesc">
                                              We respect your privacy and will
                                              only use your information for the
                                              purpose of providing you with our
                                              products and services. Your
                                              information will never be shared
                                              with third-party marketers.
                                            </p>
                                          </div>
                                          <fieldset
                                            style="border: none"
                                            class="webform-custominputs"
                                          >
                                            <div class="inputs">
                                              <div>
                                                <label
                                                  for="email"
                                                  class="requiredInput"
                                                  >Email
                                                </label>
                                                <input
                                                  maxlength="81"
                                                  class="form-control webform__form-control"
                                                  name="email"
                                                  size="20"
                                                  type="email"
                                                  required=""
                                                  placeholder="Please enter your email address"
                                                />
                                              </div>
                                              <div>
                                                <label for="field_firstname"
                                                  >First name
                                                </label>
                                                <input
                                                  maxlength="100"
                                                  class="form-control webform__form-control"
                                                  name="field_firstname"
                                                  size="20"
                                                  type="text"
                                                  false=""
                                                  placeholder="Please enter your first name"
                                                />
                                              </div>
                                              <div>
                                                <label for="field_lastname"
                                                  >Last name
                                                </label>
                                                <input
                                                  maxlength="100"
                                                  class="form-control webform__form-control"
                                                  name="field_lastname"
                                                  size="20"
                                                  type="text"
                                                  false=""
                                                  placeholder="Please enter your last name"
                                                />
                                              </div>
                                            </div>
                                            <div class="checkboxes"></div>
                                            <div
                                              class="webform-lists"
                                              style="display: none"
                                            >
                                              <div
                                                style="display: flex"
                                                class="checkboxes js-Luxury Holidays Direct - NL Subscriptions 2024"
                                              >
                                                <label class="container-box">
                                                  <input
                                                    type="checkbox"
                                                    name="publiclistid"
                                                    id="STIczovo"
                                                    value="cd405fda-994e-40f5-9aa8-c946073594c9"
                                                    checked="checked"
                                                  />
                                                </label>
                                                <label
                                                  class="publiclistlabel"
                                                  for="STIczovo"
                                                  >Luxury Holidays Direct - NL
                                                  Subscriptions 2024
                                                </label>
                                              </div>
                                            </div>
                                            <div class="consent"></div>
                                            <p id="ewf_datadisclaimer">
                                              We will keep your personal details
                                              secure and use the information you
                                              provide consistently with
                                              applicable privacy and data
                                              protection laws and the terms of
                                              our Privacy Policy:
                                              https://www.luxuryholidaysdirect.com/privacy-policy
                                            </p>
                                          </fieldset>
                                          <fieldset
                                            class="webform-options style-updated"
                                            style="border: none"
                                          >
                                            <input
                                              type="submit"
                                              name="submit"
                                              value="Subscribe Now"
                                              id="eesubmit"
                                              class="EWF__subscribe"
                                            />
                                            <input
                                              type="hidden"
                                              name="publicaccountid"
                                              value="7ed18b4e-56d5-423c-878e-f8a4a07cbc53"
                                            />
                                            <input
                                              type="hidden"
                                              name="returnUrl"
                                              value=""
                                            />
                                            <input
                                              type="hidden"
                                              name="activationReturnUrl"
                                              value=""
                                            />
                                            <input
                                              type="hidden"
                                              name="alreadyactiveurl"
                                              value=""
                                            />
                                            <input
                                              type="hidden"
                                              name="activationTemplate"
                                              value=""
                                            />
                                            <input
                                              type="hidden"
                                              name="source"
                                              value="WebForm"
                                            />
                                            <input
                                              type="hidden"
                                              name="verifyemail"
                                              value="false"
                                              id="ewf_verifyEmails"
                                            />
                                            <input
                                              type="hidden"
                                              id="ewf_captcha"
                                              name="captcha"
                                              value="true"
                                            />
                                            <input
                                              type="hidden"
                                              name="sendActivation"
                                              value="true"
                                            />
                                            <input
                                              type="hidden"
                                              name="notifyEmail"
                                              value=""
                                            />
                                            <button
                                              type="button"
                                              name="dismissForm"
                                              id="ewf_dismissForm"
                                              style="
                                                display: none;
                                                position: absolute;
                                                top: 0;
                                                right: 0;
                                                float: right;
                                              "
                                            >
                                              X
                                            </button>
                                          </fieldset>
                                          <div
                                            id="ewf_style"
                                            class="Inline"
                                          ></div>
                                        </form>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div
                              class="layer_2"
                              style="
                                max-width: 512px;
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                class="edcontent"
                                style="border-collapse: collapse; width: 100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edtext"
                                      style="
                                        padding: 20px;
                                        text-align: left;
                                        color: #32325c;
                                        font-size: 13px;
                                        font-family: 'Open Sans',
                                          'Helvetica Neue', Helvetica, Arial,
                                          sans-serif;
                                        word-break: break-word;
                                        direction: ltr;
                                        box-sizing: border-box;
                                        width: 100%;
                                      "
                                    >
                                      <p>
                                        <strong
                                          ><span style="font-size: 24px"
                                            ><br /></span
                                        ></strong>
                                      </p>
                                      <p>
                                        <strong
                                          ><span
                                            style="
                                              font-size: 24px;
                                              color: #2b9297;
                                            "
                                            ><br /></span
                                        ></strong>
                                      </p>
                                      <p>
                                        <strong
                                          ><span
                                            style="
                                              font-size: 24px;
                                              color: #2b9297;
                                            "
                                            >Why Choose&nbsp;Luxury Holidays
                                            Direct?</span
                                          ></strong
                                        >
                                      </p>
                                      <p><br /></p>
                                      <p>
                                        <span style="font-size: 18px"
                                          ><br
                                        /></span>
                                      </p>
                                      <p>
                                        <span style="font-size: 18px"
                                          >• <strong>Exclusive deals</strong> on
                                          luxury accommodations</span
                                        >
                                      </p>
                                      <p>
                                        <span style="font-size: 18px"
                                          ><br />•
                                          <strong>Personalised travel</strong>
                                          recommendations</span
                                        >
                                      </p>
                                      <p>
                                        <span style="font-size: 18px"
                                          ><br />• Expert travel advisors
                                          <strong>at your service</strong></span
                                        >
                                      </p>
                                      <p>
                                        <span style="font-size: 18px"
                                          ><br />• Handpicked destinations for
                                          <strong
                                            >unforgettable experiences</strong
                                          ></span
                                        >
                                      </p>
                                      <p>
                                        <span style="font-size: 18px"
                                          ><br />•
                                          <strong>Seamless booking</strong>
                                          process and
                                          <strong>24/7 support</strong></span
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                          <td valign="top"></td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="drow"
                      valign="top"
                      align="center"
                      style="
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-size: 0px;
                        text-align: center;
                      "
                    >
                      <table
                        width="100%"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tr>
                          <td valign="top">
                            <div
                              class="layer_2"
                              style="
                                max-width: 1024px;
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                class="edcontent"
                                style="border-collapse: collapse; width: 100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edtext"
                                      style="
                                        padding: 20px;
                                        text-align: left;
                                        color: #32325c;
                                        font-size: 13px;
                                        font-family: 'Open Sans',
                                          'Helvetica Neue', Helvetica, Arial,
                                          sans-serif;
                                        word-break: break-word;
                                        direction: ltr;
                                        box-sizing: border-box;
                                      "
                                    >
                                      <p
                                        class="text-center"
                                        style="text-align: center"
                                      >
                                        <strong
                                          ><span
                                            style="
                                              color: #2b9297;
                                              font-size: 30px;
                                            "
                                            >OUR PROMISE TO YOU</span
                                          ></strong
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="drow"
                      valign="top"
                      align="center"
                      style="
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-size: 0px;
                        text-align: center;
                      "
                    >
                      <table
                        width="100%"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tr>
                          <td valign="top">
                            <div
                              class="layer_2"
                              style="
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                                max-width: 1024px;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                class="edcontent"
                                style="
                                  border-collapse: collapse;
                                  width: 100%;
                                  background-color: #f0f2f8;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edtext"
                                      style="
                                        padding: 20px;
                                        text-align: left;
                                        color: #32325c;
                                        font-size: 13px;
                                        font-family: 'Open Sans',
                                          'Helvetica Neue', Helvetica, Arial,
                                          sans-serif;
                                        word-break: break-word;
                                        direction: ltr;
                                        box-sizing: border-box;
                                      "
                                    >
                                      <p>
                                        <span style="font-size: 18px"
                                          >•&nbsp; &nbsp;Celebrating
                                          <strong
                                            >over 40 years in luxury
                                            travel</strong
                                          >, ensuring a
                                          <strong>seamless experience</strong>
                                          with our
                                          <strong>personalised service</strong
                                          >.</span
                                        >
                                      </p>
                                      <p>
                                        <span style="font-size: 18px"
                                          >•&nbsp; &nbsp;Discover a world of
                                          possibilities with our
                                          <strong>400+ holidays</strong>,
                                          <strong
                                            >tailored for any budget</strong
                                          >.</span
                                        >
                                      </p>
                                      <p>
                                        <span style="font-size: 18px"
                                          >•&nbsp; &nbsp;<strong
                                            >Expert destination advice</strong
                                          >
                                          from our
                                          <strong>dedicated specialists</strong
                                          >, providing rapid, tailored
                                          responses.</span
                                        >
                                      </p>
                                      <p>
                                        <span style="font-size: 18px"
                                          >•&nbsp; &nbsp;A
                                          <strong>trusted</strong>, family-owned
                                          business offering independent and
                                          <strong
                                            >reliable travel guidance</strong
                                          >.</span
                                        >
                                      </p>
                                      <p>
                                        <span style="font-size: 18px"
                                          >•&nbsp; &nbsp;<strong
                                            >Guaranteed </strong
                                          >best prices with our Price Match
                                          Promise.</span
                                        >
                                      </p>
                                      <p>
                                        <span style="font-size: 18px"
                                          >•&nbsp; &nbsp;<strong
                                            >Easy booking</strong
                                          >
                                          with
                                          <strong>low deposits</strong> and
                                          <strong
                                            >flexible payment options</strong
                                          >.</span
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="drow"
                      valign="top"
                      align="center"
                      style="
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-size: 0px;
                        text-align: center;
                      "
                    >
                      <table
                        width="100%"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tr>
                          <td valign="top">
                            <div
                              class="layer_2"
                              style="
                                max-width: 1024px;
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                class="edcontent"
                                style="border-collapse: collapse; width: 100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edtext"
                                      style="
                                        padding: 20px;
                                        text-align: left;
                                        color: #32325c;
                                        font-size: 13px;
                                        font-family: 'Open Sans',
                                          'Helvetica Neue', Helvetica, Arial,
                                          sans-serif;
                                        word-break: break-word;
                                        direction: ltr;
                                        box-sizing: border-box;
                                      "
                                    >
                                      <span style="color: #2b9297">​</span>
                                      <p
                                        class="text-center"
                                        style="text-align: center"
                                      >
                                        <span
                                          style="
                                            font-size: 24px;
                                            color: #2b9297;
                                          "
                                          ><u
                                            >What Our Customers Are Saying</u
                                          ></span
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="drow"
                      valign="top"
                      align="center"
                      style="
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-size: 0px;
                        text-align: center;
                      "
                    >
                      <table
                        width="100%"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tr>
                          <td valign="top">
                            <div
                              class="layer_2"
                              style="
                                max-width: 1024px;
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                class="edcontent"
                                style="border-collapse: collapse; width: 100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edimg"
                                      style="
                                        padding: 20px;
                                        box-sizing: border-box;
                                        text-align: center;
                                      "
                                    >
                                      <a
                                        href="https://www.feefo.com/en-GB/reviews/luxury-holidays-direct"
                                      >
                                        <img
                                          src="./assets/images/lhd/Feefo_Rating_03_2023.png"
                                          alt="Image"
                                          style="
                                            border-width: 0;
                                            border-style: none;
                                            min-width: 250px;
                                          "
                                          width="80%"
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edtext"
                                      style="
                                        padding: 20px;
                                        text-align: left;
                                        color: #32325c;
                                        font-size: 13px;
                                        font-family: 'Open Sans',
                                          'Helvetica Neue', Helvetica, Arial,
                                          sans-serif;
                                        word-break: break-word;
                                        direction: ltr;
                                        box-sizing: border-box;
                                      "
                                    >
                                      <p
                                        class="text-center"
                                        style="text-align: center"
                                      >
                                        <span style="color: #2b9297"
                                          ><strong
                                            ><span style="font-size: 24px"
                                              >Don't just take our word for
                                              it.&nbsp;</span
                                            ></strong
                                          ></span
                                        >
                                      </p>
                                      <p
                                        class="text-center"
                                        style="text-align: center"
                                      >
                                        <span style="color: #2b9297"
                                          ><strong
                                            ><span style="font-size: 24px"
                                              >See what our happy customers have
                                              to say about their
                                              experiences.</span
                                            ></strong
                                          ></span
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="drow"
                      valign="top"
                      align="center"
                      style="
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-size: 0px;
                        text-align: center;
                      "
                    >
                      <table
                        width="100%"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tr>
                          <td valign="top">
                            <div
                              class="layer_2"
                              style="
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                                max-width: 1024px;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                class="edcontent"
                                style="
                                  border-collapse: collapse;
                                  width: 100%;
                                  background-color: #f0f2f8;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edtext"
                                      style="
                                        padding: 20px;
                                        text-align: left;
                                        color: #32325c;
                                        font-size: 13px;
                                        font-family: 'Open Sans',
                                          'Helvetica Neue', Helvetica, Arial,
                                          sans-serif;
                                        word-break: break-word;
                                        direction: ltr;
                                        box-sizing: border-box;
                                      "
                                    >
                                      <p>
                                        "Excellent service again making our
                                        holiday booking a pleasure"
                                      </p>
                                      <p>— Carl C., Wolverhampton</p>
                                      <p><br /></p>
                                      <p>
                                        "I have used them for a number of years
                                        now to travel all around the world. I
                                        deal exclusively with a travel
                                        consultant who knows our specific
                                        requirements and always manages to get
                                        us a little something extra."
                                      </p>
                                      <p>— Tony N., UK</p>
                                      <p><br /></p>
                                      <p>
                                        "Another holiday booked with this
                                        company. I always return to them as find
                                        they are always competitive on prices.
                                        Most importantly, so very helpful and
                                        patient with all my requests and changes
                                        of itinerary. Always polite and
                                        friendly. No hesitation in
                                        recommending."
                                      </p>
                                      <p>— T.C., UK</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="drow"
                      valign="top"
                      align="center"
                      style="
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-size: 0px;
                        text-align: center;
                      "
                    >
                      <table
                        width="100%"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tr>
                          <td valign="top">
                            <div
                              class="layer_2"
                              style="
                                max-width: 1024px;
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                cellpadding="0"
                                class="edcontent"
                                style="border-collapse: collapse; width: 100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edimg"
                                      style="
                                        padding: 20px;
                                        box-sizing: border-box;
                                        text-align: center;
                                      "
                                    >
                                      <img
                                        src="./assets/images/lhd/LHD_email_-_credentials.png"
                                        alt="Image"
                                        style="
                                          border-width: 0px;
                                          border-style: none;
                                          max-width: 653px;
                                          width: 80%;
                                        "
                                        width="100%"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="drow"
                      valign="top"
                      align="center"
                      style="
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-size: 0px;
                        text-align: center;
                      "
                    >
                      <table
                        width="100%"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tr>
                          <td valign="top">
                            <div
                              class="layer_2"
                              style="
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                                max-width: 1024px;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                cellpadding="0"
                                class="edcontent"
                                style="border-collapse: collapse; width: 100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edimg"
                                      style="
                                        text-align: center;
                                        padding: 20px;
                                        box-sizing: border-box;
                                      "
                                    >
                                      <img
                                        src="./assets/images/lhd/LHD_NL_top_image_28112023-min.png"
                                        alt="Image"
                                        style="
                                          border-width: 0px;
                                          border-style: none;
                                          max-width: 1600px;
                                          width: 100%;
                                        "
                                        width="100%"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="drow"
                      valign="top"
                      align="center"
                      style="
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-size: 0px;
                        text-align: center;
                      "
                    >
                      <table
                        width="100%"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                      >
                        <tr>
                          <td valign="top">
                            <div
                              class="layer_2"
                              style="
                                max-width: 1024px;
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                              "
                            >
                              <table
                                border="0"
                                cellspacing="0"
                                class="edcontent"
                                style="border-collapse: collapse; width: 100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      valign="top"
                                      class="edtext"
                                      style="
                                        padding: 20px;
                                        text-align: left;
                                        color: #32325c;
                                        font-size: 13px;
                                        font-family: 'Open Sans',
                                          'Helvetica Neue', Helvetica, Arial,
                                          sans-serif;
                                        word-break: break-word;
                                        direction: ltr;
                                        box-sizing: border-box;
                                      "
                                    >
                                      <p
                                        class="text-center"
                                        style="text-align: center"
                                      >
                                        <span style="font-size: 20px"
                                          >Need more ino?&nbsp;Call your expert
                                          travel consultants for more
                                          information on</span
                                        >
                                      </p>
                                      <p
                                        class="text-center"
                                        style="text-align: center"
                                      >
                                        <span style="font-size: 30px"
                                          ><a
                                            href="tel:02087747298"
                                            style="text-decoration: none"
                                          ></a></span
                                        ><a
                                          href="Tel:020 8774 7298"
                                          style="text-decoration: none"
                                          ><span style="font-size: 30px"
                                            >020 8774 7298</span
                                          ></a
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </tbody>
</table> -->
<!-- </body> -->
<div
  class="newsletter-container"
  [ngStyle]="{
    'background-image': 'url(assets/images/lhd/lhd-banner-min.png)',
    'background-position': 'center',
    'background-size': 'cover',
    'background-repeat': 'no-repeat'
  }"
>
  <!-- <img
    src="assets/images/lhd/LHD_NL_top_image_28112023-min.png"
    alt="luxury-holidays-direct"
    class="bg-img"
  /> -->
  <div class="body-content">
    <div class="slider-container" style="height: 519px">
      <!-- <div class="slide-item">123</div> -->
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <!-- <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div> -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="item-content">
              <h2 class="title-new">
                Tailor Your Dream Getaways: <br />

                Get Personalised Travel News! <br />
                Subscribe to Luxury Holidays Direct newsletter
              </h2>
            </div>
          </div>
          <div class="carousel-item">
            <div class="item-content">
              <h2 class="title-new">Why Choose Luxury Holidays Direct?</h2>
              <ul>
                <li>
                  <strong>Exclusive deals</strong> on luxury accommodations
                </li>
                <li><strong>Personalised travel </strong>recommendations</li>
                <li>
                  Expert travel advisors <strong> at your service</strong>
                </li>
                <li>
                  Handpicked destinations for
                  <strong>unforgettable experiences</strong>
                </li>
                <li>
                  <strong>Seamless booking</strong> process and
                  <strong>24/7 support</strong>
                </li>
              </ul>
            </div>
          </div>
          <div class="carousel-item">
            <div class="item-content">
              <h2 class="title-new" id="promise">Our promise to you</h2>

              <ul>
                <li>
                  Celebrating <strong>over 40 years in luxury travel</strong>,
                  ensuring a <strong>seamless experience</strong> with our
                  personalised service.
                </li>

                <li>
                  Discover a world of possibilities with our
                  <strong>400+ holidays, tailored for any budget.</strong>
                </li>

                <li>
                  <strong>Expert destination advice </strong>from our
                  <strong>dedicated specialists,</strong>
                  providing rapid, tailored responses.
                </li>

                <li>
                  A <strong>trusted,</strong> family-owned business offering
                  independent and
                  <strong> reliable travel guidance.</strong>
                </li>

                <li>
                  <strong>Guaranteed </strong>best prices with our Price Match
                  Promise.
                </li>

                <li>
                  <strong> Easy booking </strong>with
                  <strong>low deposits </strong>and
                  <strong>flexible payment options.</strong>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="carousel-item">
            <div class="item-content">
              <h2 class="title-new" style="margin-bottom: 0">
                What our customers said
              </h2>

              <p>
                <span class="parole"
                  >"Excellent service again making our holiday booking a
                  pleasure" </span
                ><br /><span class="name">Carl C., Wolverhampton</span>
              </p>
              <p>
                <span class="parole"
                  >"I have used them for a number of years now to travel all
                  around the world. I deal exclusively with a travel consultant
                  who knows our specific requirements and always manages to get
                  us a little something extra." </span
                ><br /><span class="name">Tony N., UK</span>
              </p>
              <p>
                <span class="parole"
                  >"Another holiday booked with this company. I always return to
                  them as find they are always competitive on prices. Most
                  importantly, so very helpful and patient with all my requests
                  and changes of itinerary. Always polite and friendly. No
                  hesitation in recommending." </span
                ><br /><span class="name">T.C., UK</span>
              </p>
            </div>
          </div> -->
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <div class="form-container">
      <img
        src="assets/images/lhd/logo-transparent.png"
        alt="logo"
        class="logo-new"
      />
      <h2 class="title-new">Subscribe to our newsletter</h2>
      <form [formGroup]="subForm" class="form-new">
        <label for="Email">Email <span style="color: red">*</span></label>
        <input
          type="email"
          placeholder="Please enter your email address"
          formControlName="email"
          [class.border-red]="
            subForm.controls['email'].invalid &&
            subForm.controls['email'].touched
          "
        />
        <label for="first-name"
          >Frist name <span style="color: red">*</span></label
        >
        <input
          type="text"
          placeholder="Please enter your first name"
          formControlName="firstName"
          [class.border-red]="
            subForm.controls['firstName'].invalid &&
            subForm.controls['firstName'].touched
          "
        />
        <label for="last-name"
          >Last name <span style="color: red">*</span></label
        >
        <input
          type="text"
          placeholder="Please enter your last name"
          formControlName="lastName"
          [class.border-red]="
            subForm.controls['lastName'].invalid &&
            subForm.controls['lastName'].touched
          "
        />
        <button
          type="submit"
          class="btn-submit"
          [disabled]="!subForm.valid"
          (click)="onSubmit()"
        >
          Submit
        </button>
      </form>
      <p class="terms-cond">
        We will keep your personal details secure and use the information you
        provide consistently with applicable privacy and data protection laws
        and the terms of our
        <a href="https://www.luxuryholidaysdirect.com/privacy-policy"
          >Privacy Policy</a
        >
      </p>
    </div>
  </div>
</div>
<!-- Button trigger modal -->

<!-- Button trigger modal -->
<button
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal"
  #btnShowModal
  style="display: none"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" style="top: 30%">
    <div class="modal-content">
      <div class="modal-header">
        <h3
          class="modal-title"
          id="exampleModalLabel"
          style="margin: 0; font-size: 13px; font-weight: 600"
        >
          {{ result.title }}
        </h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          #btnCloseModal
          style="display: none"
        ></button>
      </div>
      <div class="modal-body" style="font-size: 13px">{{ result.message }}</div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-submit"
          data-bs-dismiss="modal"
          (click)="onClickedOk()"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</div>
